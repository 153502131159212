import { trackAscEvent } from '~/composables/asc/trackAscEvent'
import type { ASCMediaInteraction } from '~/utils/types/asc'
import { type ASCPageType, ASCEvent } from '~/utils/types/asc/enums'
import type { Vehicle } from '~/utils/types/inventory'

export const trackMediaInteractionEvent = (
    params: ASCMediaInteractionParams,
) => {
    const eventStore = useEventStore()

    const { pageType, data } = params
    const vehicle: Vehicle | null = params.vehicle ?? null

    const event: ASCMediaInteraction = {
        ...{
            page_type: pageType,
            event_action: data.event_action,
            event_action_result: data.event_action_result,
            media_type: data.media_type,
            link_url: data.link_url ?? '',
        },
        ...(vehicle ? eventStore.formatVehicleForASC(vehicle) : {}),
    }

    trackAscEvent({
        eventName: ASCEvent.MEDIA_INTERACTION,
        event,
        pageType,
    })
}

export interface ASCMediaInteractionParams {
    pageType: ASCPageType
    data: Record<string, any>
    vehicle?: Vehicle | null
}
