<template>
    <component
        :is="electricIcon"
        v-if="electricIcon !== null"
        class="ml-1 w-4 h-4 cursor-pointer text-green-600"
    />
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { BoltIcon } from '@heroicons/vue/24/solid'
import type { Vehicle } from '~/utils/types/inventory'
import LeafIcon from '@/assets/icons/leaf.svg'
import PlugIcon from '@/assets/icons/plug.svg'

const props = defineProps({
    vehicle: {
        type: Object as PropType<Vehicle>,
        required: true,
    },
})

const electricIcon = computed(() => {
    if (!props.vehicle.electric) {
        return null
    }

    switch (props.vehicle.fuel) {
        case 'Electric':
            return BoltIcon
        case 'Hybrid':
            return LeafIcon
        case 'Plug-In Hybrid':
            return PlugIcon
        default:
            return null
    }
})
</script>
