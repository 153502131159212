<template>
    <Menu as="div" class="absolute right-4 -bottom-4 z-30">
        <div>
            <MenuButton
                class="bg-white rounded-full flex items-center justify-center p-2 border border-gray-200"
            >
                <EllipsisVerticalIcon class="h-5 w-5" />
            </MenuButton>
        </div>
        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <MenuItems
                class="absolute right-0 z-80 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
                <div class="py-1">
                    <!--                    <MenuItem v-slot="{ active }">-->
                    <!--                        <button-->
                    <!--                            :class="[-->
                    <!--                                active-->
                    <!--                                    ? 'bg-gray-100 text-gray-900'-->
                    <!--                                    : 'text-gray-700',-->
                    <!--                                'w-full flex items-center px-4 py-2 text-sm',-->
                    <!--                            ]"-->
                    <!--                        >-->
                    <!--                            <HeartIcon class="h-5 w-5" />-->
                    <!--                            <span class="ml-3">-->
                    <!--                                {{ t('add_to_favorites') }}-->
                    <!--                            </span>-->
                    <!--                        </button>-->
                    <!--                    </MenuItem>-->
                    <!--                    <MenuItem v-slot="{ active }">-->
                    <!--                        <a-->
                    <!--                            href="#"-->
                    <!--                            :class="[-->
                    <!--                                active-->
                    <!--                                    ? 'bg-gray-100 text-gray-900'-->
                    <!--                                    : 'text-gray-700',-->
                    <!--                                'flex items-center px-4 py-2 text-sm',-->
                    <!--                            ]"-->
                    <!--                        >-->
                    <!--                            <ArrowsRightLeftIcon class="h-5 w-5" />-->
                    <!--                            <span class="ml-3">{{ t('compare') }}</span></a-->
                    <!--                        >-->
                    <!--                    </MenuItem>-->
                    <MenuItem v-if="isSupported" v-slot="{ active }">
                        <span
                            :class="[
                                active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                'cursor-pointer flex items-center px-4 py-2 text-sm w-full text-left',
                            ]"
                            @click="shareVehicle"
                        >
                            <ShareIcon class="h-5 w-5" />
                            <span class="ml-3">{{ t('share') }}</span>
                        </span>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                        <button
                            :class="[
                                active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                'w-full flex items-center px-4 py-2 text-sm',
                            ]"
                            @click="openOptionsModal"
                        >
                            <WindowIcon class="h-5 w-5" />
                            <span class="ml-3">
                                {{ t('vdp_features_and_specs') }}
                            </span>
                        </button>
                    </MenuItem>
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>
<script setup lang="ts">
import { useShare } from '@vueuse/core'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import {
    // ArrowsRightLeftIcon,
    EllipsisVerticalIcon,
    // HeartIcon,
    ShareIcon,
    WindowIcon,
} from '@heroicons/vue/24/outline'
import type { PropType } from 'vue'
import type { Vehicle } from '~/utils/types/inventory'
import { vehicleNameFromVehicle } from '~/utils/functions'
import type { Location } from '~/utils/types/location'
import type { AutoZnetworkEntity } from '~/utils/types/global'

const modalStore = useModalStore()
const domainStore = useDomainStore()
const locationStore = useLocationStore()
const localePath = useLocalePath()

const props = defineProps({
    vehicle: {
        type: Object as PropType<Vehicle>,
        required: true,
    },
})

const location = computed<Location | null>(
    () => locationStore.getLocation(props.vehicle.location_id) ?? null,
)

const { t } = useI18n()

const { share, isSupported } = useShare()

const entity = computed<AutoZnetworkEntity>(
    () => domainStore.autoZnetworkEntity,
)

function shareVehicle() {
    let text = `Check out this ${vehicleNameFromVehicle(props.vehicle)} for sale`

    if (location.value !== null) {
        text += ` in ${location.value.address.city}, ${location.value.address.state}`
    }

    text += ` from ${entity.value.name}!`

    share({
        title: props.vehicle.name,
        text,
        url: localePath(`/for-sale/${props.vehicle.slug}`),
    })
}

async function openOptionsModal() {
    const vehicle: Vehicle | null = await $fetch(`/api/vdp/${props.vehicle.id}`)

    if (vehicle) {
        modalStore.openModal({
            component: 'VehicleOptionsModal',
            title: t('vdp_features_and_specs'),
            params: {
                vehicle,
            },
        })
    }
}
</script>
