import { trackAscEvent } from '~/composables/asc/trackAscEvent'
import type { ASCCTAInteraction } from '~/utils/types/asc'
import { type ASCPageType, ASCEvent } from '~/utils/types/asc/enums'
import type { Vehicle } from '~/utils/types/inventory'

export const trackCtaInteractionEvent = (params: ASCCtaInteractionParams) => {
    const eventStore = useEventStore()

    const { pageType, data } = params
    const vehicle: Vehicle | null = params.vehicle ?? null

    const event: ASCCTAInteraction = {
        ...{
            page_type: pageType,
            element_text: data.element_text,
            element_color: data.element_color ?? null,
            element_order: data.element_order ?? null,
            element_type: data.element_type,
            element_subtype: data.element_subtype,
            event_action: data.event_action,
            event_action_result: data.event_action_result,
            link_url: data.link_url ?? '',
            product_name: data.product_name ?? undefined,
            affiliation: data.affiliation ?? undefined,
        },
        ...(vehicle ? eventStore.formatVehicleForASC(vehicle) : {}),
    }

    trackAscEvent({
        eventName: ASCEvent.CTA_INTERACTION,
        event,
        pageType,
    })
}

export interface ASCCtaInteractionParams {
    pageType: ASCPageType
    data: Record<string, any>
    vehicle?: Vehicle | null
}
