<template>
    <span
        v-if="vehicle.price.selling && vehicle.price.selling > 0"
        :data-cg-vin="vehicle.vin"
        :data-cg-price="vehicle.price.selling"
        :data-cg-height="height"
        class="-mb-1"
        @click="addCTAEvent"
    />
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { Vehicle } from '~/utils/types/inventory'
import { trackCtaInteractionEvent } from '~/composables/asc/events/CtaInteraction'
import {
    ASCElementSubType,
    ASCElementType,
    ASCEventAction,
    ASCEventActionResult,
    ASCPageType,
} from '~/utils/types/asc/enums'

const eventStore = useEventStore()

const props = defineProps({
    vehicle: {
        type: Object as PropType<Vehicle>,
        required: true,
    },
    height: {
        type: Number,
        default: 60,
    },
})

function addCTAEvent() {
    trackCtaInteractionEvent({
        pageType: eventStore.ascPageType ?? ASCPageType.UNKNOWN,
        data: {
            element_type: ASCElementType.POPUP,
            element_subtype: ASCElementSubType.CTA_BUTTON,
            event_action: ASCEventAction.CLICK,
            event_action_result: ASCEventActionResult.OPEN,
            product_name: 'CarGurus Deal Ratings',
            affiliation: 'cargurus',
        },
        vehicle: props.vehicle,
    })
}
</script>
