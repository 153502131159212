<template>
    <div>
        <NuxtInventoryImage
            :src="vehicle.image"
            :width="500"
            :ratio="!disableImageCrop ? VehicleImageRatio.RATIO_4_3 : undefined"
            sizes="xs:400px sm:500px"
            :settings="{
                overlay,
                fit: !disableImageCrop ? VehicleImageFit.COVER : undefined,
                position: !disableImageCrop
                    ? VehicleImagePosition.ATTENTION
                    : undefined,
            }"
            :alt="`${vehicle.stock} - ${vehicle.name}`"
            :blur-hash="firstImage?.blur_hash"
            classes="w-full h-full object-contain object-center"
            @click="imageClicked"
        />
        <GridVehicleTagline
            v-if="vehicle.tag_line && showTagLine"
            :vehicle="vehicle"
        />
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { Vehicle, VehicleImage } from '~/utils/types/inventory'
import GridVehicleTagline from '~/components/SearchResultsPage/Vehicle/GridVehicle/GridVehicleTagline.vue'
import {
    VehicleImageFit,
    VehicleImagePosition,
    VehicleImageRatio,
} from '~/utils/types/inventory/enums'
import { trackMediaInteractionEvent } from '~/composables/asc/events/MediaInteraction'
import {
    ASCEventAction,
    ASCEventActionResult,
    ASCMediaType,
    ASCPageType,
} from '~/utils/types/asc/enums'

const localePath = useLocalePath()
const eventStore = useEventStore()

const props = defineProps({
    vehicle: {
        type: Object as PropType<Vehicle>,
        required: true,
    },
    firstImage: {
        type: Object as PropType<VehicleImage | null>,
        default: null,
    },
    overlay: {
        type: String as PropType<string | null>,
        default: null,
    },
    showTagLine: {
        type: Boolean,
        default: true,
    },
    disableImageCrop: {
        type: Boolean,
        default: false,
    },
})

function imageClicked() {
    trackMediaInteractionEvent({
        pageType: eventStore.ascPageType ?? ASCPageType.UNKNOWN,
        data: {
            event_action: ASCEventAction.CLICK,
            event_action_result: ASCEventActionResult.OPEN,
            media_type: ASCMediaType.IMAGE,
            link_url: localePath(`/for-sale/${props.vehicle.slug}`),
        },
        vehicle: props.vehicle,
    })
}
</script>
