export const onIntersect = (params: IntersectParams) => {
    const { element, callback } = params
    const outCallback: CallableFunction =
        params.outCallback !== undefined ? params.outCallback : () => {}
    const once: boolean = params.once ?? false
    const threshold: number = params.threshold ?? 0

    const options: Record<string, any> = {
        ...(params.options ?? {}),
        ...{
            threshold,
        },
    }

    // Initiate the observer
    const observer: IntersectionObserver = new IntersectionObserver(
        ([entry]) => {
            // // If the element to watch is intersecting within the threshold
            if (entry.isIntersecting && entry.intersectionRatio >= threshold) {
                // Run the callback
                callback(entry)

                // If the callback should only run once, unobserve the element
                if (once) {
                    observer.unobserve(entry.target)
                }
            } else {
                // If the element is not intersecting, run the (optional) un-intersecting callback
                outCallback(entry)
            }
        },
        options,
    )

    observer.observe(element)

    // Returns the observer
    return observer
}

interface IntersectParams {
    element: Element
    callback: CallableFunction
    outCallback?: CallableFunction
    once?: boolean
    threshold?: number
    options?: Record<string, any>
}
