<template>
    <div>
        <div
            v-if="buttonsToDisplay.length > 0"
            :class="[
                'flex flex-col space-y-4',
                type === CTAButtonComponentType.VDP
                    ? 'bg-gray-50 p-6 border-t border-gray-200'
                    : 'mt-4',
            ]"
        >
            <div
                v-for="(button, index) in buttonsToDisplay"
                :key="index"
                class="shadow-md hover:shadow-lg"
            >
                <DealerMeNuxtLink
                    v-if="button.component === LeadModalComponent.FINANCING"
                    class="block btn w-full text-center"
                    :href="`/finance-application?vehicle=${vehicle.id}`"
                >
                    {{ button.ctaButtonText }}
                </DealerMeNuxtLink>
                <CapitalOneLeadsNavigator
                    v-else-if="
                        button.component === LeadModalComponent.CAPITAL_ONE
                    "
                    :vehicle="vehicle"
                />
                <button
                    v-else
                    class="btn w-full"
                    @click="emit('click', button)"
                >
                    {{ button.ctaButtonText }}
                </button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { Vehicle, VehicleIncentive } from '~/utils/types/inventory'
import { useBuildLeadComponentData } from '~/composables/vehicle-description-page/buildLeadComponentData'
import { LeadModalComponent } from '~/utils/types/leadModal'
import type { StoryblokLeadButton } from '~/utils/types/storyblok'
import {
    CTAButtonComponentType,
    type LeadButtonData,
} from '~/utils/schemas/vdpLeadComponentSchema'

const storyblokStore = useStoryblokStore()
const domainStore = useDomainStore()

const props = defineProps({
    type: {
        type: String as PropType<CTAButtonComponentType>,
        required: true,
    },
    vehicle: {
        type: Object as PropType<Vehicle>,
        required: true,
    },
    incentives: {
        type: Array as PropType<VehicleIncentive[]>,
        required: true,
    },
})

const emit = defineEmits(['click'])

const honkr = computed(() => domainStore.honkr)
const depositsEnabled = computed<boolean>(
    () =>
        honkr.value.deposits.enabled &&
        honkr.value.deposits.type !== null &&
        honkr.value.organizationId !== null,
)

const defaultComponents: DefaultComponents = {
    components: [
        LeadModalComponent.CONFIRM_AVAILABILITY,
        LeadModalComponent.SCHEDULE_APPOINTMENT,
        LeadModalComponent.FINANCING,
    ],
    soldComponents: [],
}

const storyblokLeadButtons = computed(() =>
    props.type === CTAButtonComponentType.VDP
        ? (storyblokStore.vdpSettings?.cta_lead_buttons ?? [])
        : (storyblokStore.srpCTAButtons ?? []),
)

const buttons = computed<LeadButtonData[]>(() =>
    storyblokLeadButtons.value.length === 0
        ? props.type === CTAButtonComponentType.SRP
            ? []
            : (defaultComponents.components
                  .map((type: LeadModalComponent) =>
                      useBuildLeadComponentData(
                          props.vehicle,
                          type,
                          props.incentives,
                      ),
                  )
                  .filter((n) => n) as LeadButtonData[])
        : (storyblokLeadButtons.value
              .map((sbItem: StoryblokLeadButton) =>
                  useBuildLeadComponentData(
                      props.vehicle,
                      sbItem.type,
                      props.incentives,
                      sbItem,
                  ),
              )
              .filter((n) => n) as LeadButtonData[]),
)

const buttonsWithFilteredIncentives = computed<LeadButtonData[]>(() =>
    buttons.value.filter(
        (button: LeadButtonData) =>
            button.component !== LeadModalComponent.VEHICLE_INCENTIVE ||
            button.incentive !== null,
    ),
)

const buttonsWithSoldFilter = computed<LeadButtonData[]>(() => {
    if (!props.vehicle.sold) {
        return buttonsWithFilteredIncentives.value
    }

    return storyblokLeadButtons.value.length === 0
        ? buttonsWithFilteredIncentives.value.filter((button: LeadButtonData) =>
              defaultComponents.soldComponents.includes(button.component),
          )
        : buttonsWithFilteredIncentives.value.filter(
              (button: LeadButtonData) => button.sold,
          )
})

const buttonsToDisplay = computed<LeadButtonData[]>(() => {
    if (
        props.vehicle.sold ||
        !depositsEnabled.value ||
        props.type === CTAButtonComponentType.SRP
    ) {
        return buttonsWithSoldFilter.value
    }

    const component: LeadButtonData | null = useBuildLeadComponentData(
        props.vehicle,
        LeadModalComponent.DEPOSIT,
    )

    return [...(component ? [component] : []), ...buttonsWithSoldFilter.value]
})

interface DefaultComponents {
    components: Array<LeadModalComponent>
    soldComponents: Array<LeadModalComponent>
}
</script>
