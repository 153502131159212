<template>
    <div
        v-if="location"
        class="bg-gray-50 p-4 border-t border-gray-200 rounded-b-lg"
    >
        <div class="group block flex-shrink-0">
            <div class="flex items-start">
                <div
                    v-if="locationIcon"
                    class="relative inline-block bg-gray-200 h-9 w-9 rounded-full"
                >
                    <img
                        class="h-9 w-9 rounded-full"
                        :src="locationIcon"
                        alt="Location Icon"
                    />
                </div>
                <div
                    :class="{
                        'ml-2': locationIcon,
                    }"
                >
                    <div
                        class="text-sm font-semibold text-gray-700 group-hover:text-gray-900"
                    >
                        {{ location.name }}
                    </div>
                    <div
                        class="flex items-center text-xs text-gray-500 group-hover:text-gray-700"
                    >
                        <MapPinIcon
                            class="mr-1 h-3 w-3 flex-none text-gray-500 group-hover:text-gray-700"
                            aria-hidden="true"
                        />
                        <span>
                            {{ location.address.city }},
                            {{ location.address.state }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { MapPinIcon } from '@heroicons/vue/24/solid'
import type { Vehicle } from '~/utils/types/inventory'
import type { Location } from '~/utils/types/location'
import { DomainEntityType } from '~/utils/types/global/enums'

const domainStore = useDomainStore()
const locationStore = useLocationStore()

const props = defineProps({
    vehicle: {
        type: Object as PropType<Vehicle>,
        required: true,
    },
})

const location = computed<Location>(() =>
    locationStore.getLocation(props.vehicle.location_id),
)

const locationIcon = computed(() =>
    domainStore.entityType === DomainEntityType.ENTERPRISE
        ? (location.value.icon ?? null)
        : null,
)
</script>
