<template>
    <div
        :class="[!vehicle.price.msrp ? 'rounded-l-md' : '', 'rounded-r-md']"
        class="relative -ml-px px-2 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
    >
        <div class="text-xs tracking-tight leading-tight">
            <span v-if="retailPrice === 0">{{ t('sale_price') }}</span>
            <span v-else class="text-gray-700">
                was
                <span class="line-through"
                    >${{ numberFormat(retailPrice) }}</span
                >
            </span>
        </div>
        <div class="flex items-center font-bold">
            <ArrowDownCircleIcon
                v-if="retailPrice > 0"
                class="h-5 w-5 mr-0.5 text-green-800 -rotate-45"
            />
            <span class="text-xl tracking-tight">
                <VehiclePrice
                    :vehicle="vehicle"
                    :hide-retail-price="true"
                    unlock-type="text"
                />
            </span>
            <!--            <ChevronDownIcon class="-mr-1 h-5 w-5" aria-hidden="true" />-->
        </div>
    </div>
</template>

<script setup lang="ts">
import { ArrowDownCircleIcon } from '@heroicons/vue/20/solid'
import type { PropType } from 'vue'
import VehiclePrice from '~/components/Vehicle/VehiclePrice.vue'
import type { Vehicle } from '~/utils/types/inventory'
import { numberFormat } from '~/utils/functions'

const domainStore = useDomainStore()

const props = defineProps({
    vehicle: {
        type: Object as PropType<Vehicle>,
        required: true,
    },
})

const { t } = useI18n()

const hidePrices = computed(
    () => domainStore.inventorySettings?.hidePrices ?? false,
)
const vehiclesHaveBeenUnlocked = computed(
    () => useCookie('vu').value ?? useCookie('pu').value ?? false,
)

const retailPrice = computed(() => {
    if (
        (vehiclesHaveBeenUnlocked.value || !hidePrices.value) &&
        props.vehicle.price?.retail &&
        props.vehicle.price?.selling &&
        props.vehicle.price?.retail > props.vehicle.price?.selling
    ) {
        return props.vehicle.price?.retail ?? 0
    }

    return 0
})
</script>
