<template>
    <div class="sm:transition-all">
        <DealerMeNuxtLink
            :href="`/for-sale/${vehicle.slug}`"
            class="relative"
            @click="updatePosition"
        >
            <GridVehicleMediaVideo
                v-if="videoAsset?.mux_url"
                :vehicle="vehicle"
                :video-asset="videoAsset"
                :first-image="firstImage"
                :overlay="overlay"
                :show-tag-line="showTagLine"
                :intersected="intersected"
                :disable-image-crop="disableImageCrop"
            />
            <GridVehicleMediaImage
                v-else
                :vehicle="vehicle"
                :first-image="firstImage"
                :overlay="overlay"
                :show-tag-line="showTagLine"
                :disable-image-crop="disableImageCrop"
            />
        </DealerMeNuxtLink>
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type {
    Vehicle,
    VehicleAsset,
    VehicleImage,
} from '~/utils/types/inventory'
import GridVehicleMediaImage from '~/components/SearchResultsPage/Vehicle/GridVehicle/Media/GridVehicleMediaImage.vue'
import GridVehicleMediaVideo from '~/components/SearchResultsPage/Vehicle/GridVehicle/Media/GridVehicleMediaVideo.vue'
import { getMainVehicleMuxVideoAsset } from '~/utils/functions'

const inventoryStore = useInventoryStore()

const props = defineProps({
    vehicle: {
        type: Object as PropType<Vehicle>,
        required: true,
    },
    firstImage: {
        type: Object as PropType<VehicleImage | null>,
        default: null,
    },
    showTagLine: {
        type: Boolean,
        default: true,
    },
    overlay: {
        type: String as PropType<string | null>,
        default: null,
    },
    position: {
        type: Number,
        default: null,
    },
    intersected: {
        type: Boolean,
        default: false,
    },
    disableImageCrop: {
        type: Boolean,
        default: false,
    },
})

const videoAsset = computed<VehicleAsset | null>(() =>
    getMainVehicleMuxVideoAsset(props.vehicle),
)

function updatePosition() {
    if (props.position) {
        inventoryStore.resultsPosition = props.position
    }
}
</script>
